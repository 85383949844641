import React from "react";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Whatsapp } from "../../assets/whatsapp.svg";
import { ReactComponent as Tiktok } from "../../assets/tiktok.svg";
import logo from "../../assets/logo-nutricion-sin-medida.png";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import {
  SendFacebook,
  SendInstagram,
  SendWhatsapp,
  SendTiktok,
} from "../../utils/utils";

export const Footer = () => {
  return (
    <div className="footer py-10 px-5 flex flex-wrap">
      <div className="lg:w-1/2 w-full flex justify-center">
        <div className="flex items-center space-x-4">
          <img
            src={logo}
            className="text-slate-900 dark:text-white w-auto h-14"
          />
          <div className="flex flex-col justify-items-center items-center">
            <p className="logo-text text-2xl text-browncustom mb-0">
              Brenda Montes de Oca <br />
            </p>
            <span className="text-sm montserrat text-greenutrialt">
              Nutricion sin medida
            </span>
          </div>
        </div>
      </div>
      <div className="lg:w-1/4 w-full lg:mt-0 mt-5">
        <p
          onClick={SendInstagram}
          className="p-2 justify-center lg:justify-start montserrat text-pinkcustom hover:text-browncustom cursor-pointertext-pinkcustom flex items-center space-x-2 cursor-pointer"
        >
          <Instagram
            width={20}
            height={20}
            className="fill-pinkcustom hover:fill-browncustom "
          />
          <span>@brendamdeoi</span>
        </p>
        <p
          onClick={SendWhatsapp}
          className="p-2 justify-center lg:justify-start montserrat text-pinkcustom flex items-center space-x-2 hover:text-browncustom cursor-pointer"
        >
          <Whatsapp
            width={20}
            height={20}
            className="fill-pinkcustom hover:fill-browncustom cursor-pointer"
          />
          <span>+52 3310156051</span>
        </p>
        <p
          onClick={SendTiktok}
          className="p-2 justify-center lg:justify-start montserrat text-pinkcustom flex items-center space-x-2 hover:text-browncustom cursor-pointer"
        >
          <Tiktok
            width={20}
            height={20}
            className="fill-pinkcustom hover:fill-browncustom cursor-pointer"
          />
          <span>@brendamdeoi</span>
        </p>
      </div>
      <div className="lg:w-1/4 w-full">
        <p
          onClick={SendFacebook}
          className="p-2 justify-center lg:justify-start montserrat text-pinkcustom flex items-center space-x-2 hover:text-browncustom cursor-pointer"
        >
          <Facebook
            width={20}
            height={20}
            className="fill-pinkcustom hover:fill-browncustom cursor-pointer"
          />
          <span>Brendamdeoi Nutrición sin medida</span>
        </p>
        <a
          href="mailto: brendamdeoi@gmail.com"
          className="p-2 justify-center lg:justify-start lg:text-left text-center montserrat text-pinkcustom flex items-center space-x-2 hover:text-browncustom cursor-pointer"
        >
          <span className="material-symbols-outlined">mail</span>
          <span>brendamdeoi@gmail.com</span>
        </a>
        <a
          href="https://goo.gl/maps/FaEWhVyrpbQkuCYs6"
          className="p-2 justify-center lg:justify-start lg:text-left text-center montserrat text-pinkcustom flex items-start space-x-2 hover:text-browncustom cursor-pointer"
        >
          <span className="material-symbols-outlined">location_on</span>
          <span>
            Basilio Vadillo 840, Talpita, 44719 <br /> Guadalajara, Jal.
          </span>
        </a>
      </div>
      <p
        onClick={SendWhatsapp}
        className="p-2 bg-lightbluecustom bg-opacity-40 rounded-full fixed bottom-10 right-6 montserrat text-pinkcustom flex items-center space-x-2 hover:text-browncustom cursor-pointer"
      >
        <Whatsapp
          width={40}
          height={40}
          className="fill-otro hover:scale-105 cursor-pointer"
        />
      </p>
      <div className="justify-center flex text-white">
        <p>Todos los derechos reservados 2023</p>
      </div>
    </div>
  );
};
