import React from "react";
import { Title } from "./Title";
import ReactMarkdown from "react-markdown";

interface props {
  id: string;
  text: string;
  img: string;
  title: string;
  initSection?: string;
  action?: () => void;
  textaction?: string;
}

export const TextAndPhoto = ({
  id,
  text,
  img,
  title,
  initSection,
  action,
  textaction,
}: props) => {
  return (
    <div
      id={id}
      className="flex flex-wrap mb-10 relative lg:flex-row-reverse gradient-brown"
    >
      {initSection && (
        <div className="py-14 w-full flex justify-center px-5 mx-auto text-center">
          <Title title={initSection} />
        </div>
      )}
      <div className="lg:w-1/2 w-full justify-center flex wow slideInRight">
        <img
          src={img}
          alt={title}
          className="object-contain rounded-lg hover:rotate-1 transition ease-in-out duration-150"
        />
      </div>
      <div className="lg:w-1/2 p-10 wow slideInLeft">
        <Title title={title} />
        <p className="montserrat parrafo mt-2">
          <ReactMarkdown children={text} />
        </p>
        {action && (
          <div className="flex justify-center mt-28">
            <button
              onClick={action}
              className="bg-browncustom hover:bg-pinkcustom hover:scale-105 ease-in-out duration-100 text-beige px-4 py-2 rounded-lg"
            >
              {textaction}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
