import React from "react";

interface props {
  title: string;
}

export const Title = ({ title }: props) => {
  return (
    <p className="kalam text-browncustom lg:text-3xl text-2xl hover:scale-105 transition ease-in-out duration-175 cursor-pointer">
      {title}
    </p>
  );
};
