export const SendWhatsapp = () => {
  const text = encodeURIComponent("Me interesa una consulta");
  let url = "https://wa.me/523310156051?text=" + text;

  window.open(url);
  console.log("entramos ok");
};

export const SendInstagram = () => {
  let url = "https://instagram.com/brendamdeoi?igshid=YmMyMTA2M2Y=";
  window.open(url);
};
export const SendFacebook = () => {
  let url = "https://www.facebook.com/profile.php?id=100070088601725&mibextid=ZbWKwL";
  window.open(url);
};
export const SendTiktok = () => {
  let url = "https://www.tiktok.com/@brendamdeoi?_t=8ZpgHAWqo7h&_r=1";
  window.open(url);
};