import React from "react";
import { CardAndPhoto } from "../../Components/CardAndPhoto";
import IMGhead from "../../assets/fotos/heade.jpeg";
import IMGhead2 from "../../assets/fotos/head2.jpeg";
import IMGComiendo from "../../assets/fotos/comiendo2-copy.jpeg";
import IMGEspejo from "../../assets/fotos/espejo-1.jpeg";
import { TextAndPhoto } from "../../Components/TextAndPhoto";
import { Title } from "../../Components/Title";
import { SendWhatsapp } from "../../utils/utils";
import { Textos } from "../../utils/text";

export const Home = () => {
  return (
    <div>
      <CardAndPhoto
        id={"aboutme"}
        img={IMGhead2}
        text={Textos.aboutMe}
        title="Acerca de mi"
      />
      <TextAndPhoto
        id={"sesionnutri"}
        img={IMGComiendo}
        text={Textos.servicio}
        title="Sesión nutricional"
        initSection="Reconstruyamos una relación sana con la comida"
        action={SendWhatsapp}
        textaction="Quiero una sesión"
      />

      <CardAndPhoto
        id={"foryou"}
        img={IMGEspejo}
        text={Textos.isForYou}
        title="Es para ti si… "
      />
      <TextAndPhoto
        id={"freeSession"}
        img={IMGhead}
        text={Textos.orientationText}
        title="Sesión de orientación gratuita "
        action={SendWhatsapp}
        textaction="Quiero una sesión gratuita"
      />
      <div className="py-20" id="location">
        <div className="w-full justify-center flex mb-10">
          <Title title={"Ubicación"} />
        </div>
        <div className="flex flex-wrap lg:min-h-[800px] min-h-[500px]">
          <div className="w-full justify-center items-center flex">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d233.29107217138235!2d-103.31086941190608!3d20.683522756588204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b176497aa05d%3A0x2b5967f2a2d4fe14!2sBasilio%20Vadillo%20840%2C%20Talpita%2C%2044719%20Guadalajara%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1677958343094!5m2!1ses-419!2smx"
              className="h-full w-full"
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
