import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

interface IProps {
  isMobile: boolean;
  setOpenMenu?: (val: boolean) => void;
}

export const Navigation = ({ isMobile, setOpenMenu }: IProps) => {
  const closeModal = () => {
    if (isMobile && setOpenMenu) setOpenMenu(false);
  };
  return (
    <>
      <Link
        className="hover:bg-pinkcustom hover:scale-110 transition ease-in-out duration-150 rounded-full px-3 py-1 cursor-pointer hover:text-beige"
        activeClass={isMobile ? "mob" : "active"}
        to="aboutme"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        onClick={closeModal}
      >
        Acerca de mi
      </Link>
      <Link
        className="hover:bg-pinkcustom hover:scale-110 transition ease-in-out duration-150 rounded-full px-3 py-1 cursor-pointer hover:text-beige"
        activeClass={isMobile ? "mob" : "active"}
        to="sesionnutri"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        onClick={closeModal}
      >
        Sesión nutricional
      </Link>
      <Link
        className="hover:bg-pinkcustom hover:scale-110 transition ease-in-out duration-150 rounded-full px-3 py-1 cursor-pointer hover:text-beige"
        activeClass={isMobile ? "mob" : "active"}
        to="foryou"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        onClick={closeModal}
      >
        Es para ti
      </Link>
      <Link
        className="hover:bg-pinkcustom hover:scale-110 transition ease-in-out duration-150 rounded-full px-3 py-1 cursor-pointer hover:text-beige"
        activeClass={isMobile ? "mob" : "active"}
        to="freeSession"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        onClick={closeModal}
      >
        Sesión gratuita
      </Link>
      <Link
        className="hover:bg-pinkcustom hover:scale-110 transition ease-in-out duration-150 rounded-full px-3 py-1 cursor-pointer hover:text-beige"
        activeClass={isMobile ? "mob" : "active"}
        to="location"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        onClick={closeModal}
      >
        Ubicación
      </Link>
    </>
  );
};
