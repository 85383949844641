export const Textos = {
  aboutMe:
    "Soy Brenda, nutrióloga con un enfoque no centrado en peso. Además de mi pasión por la nutrición, me gustan las manualidades, cocinar, el mar y observar atardeceres.\
\nTrabajo bajo los principios de HAES® (Salud en todas las tallas) y el modelo de Alimentación Intuitiva. \
\nMis consultas se basan en ayudar a las personas a mejorar su relación con los alimentos y la conexión con su cuerpo, así como a crear hábitos que promuevan la salud de manera integral.\n\n\
  ✓ Licenciatura en Nutrición / UDG / 12424727\n\
  ✓ Certificación en Alimentación Respetuosa y Liberación Corporal (Nutriológicas)\n\
  ✓ Curso de Psiconutrición aplicada a la consulta (Nutrimentalia)\n\
  ✓ Certificación Internacional en Psiconutrición (Instituto Universitario Vive Sano) \n\
  ✓ Certificación Internacional en Trastornos de la Conducta Alimentaria (Instituto Universitario Vive Sano)\n\
  ✓ Diplomado Internacional en Psicotrauma (Feelink / Universidad Anáhuac)\n\
",
  servicio:
    "\
\n\n**Duración:** 1 hora\
\n\n**Modalidad:** En línea o Presencial en Guadalajara, Jal., México.\
\n\n**Costo:** $400 MXN\
\n\n**Incluye:** \n\
• Acompañamiento por Whatsapp.\n\
• Asesoría nutricional para padecimientos específicos.\n\
• Herramientas: Ejercicios de práctica, videos, documentales, podcast, etc.\n\
• En caso de ser necesario se brinda una guía de alimentación (sin conteo de porciones o calorías).\n\
\n\n**¿Qué trabajamos?** \n\
• Mejorar tu relación con la comida y/o ejercicio.\n\
• Identificar tus señales de hambre y saciedad.\n\
• Crear hábitos saludables y sostenibles que aporten a un bienestar integral en todos los tamaños de cuerpo.\n\
• Llevar a cabo una alimentación flexible, individualizada, amable, sin reglas ni obsesiones que se adapte a tus necesidades y  posibilidades.\n\
• Adaptar tu alimentación para la mejora de padecimientos específicos (examenes sanguíneos fuera de los parámetros normales, problemas gastrointestinales, hipertensión, diabetes, resistencia a la insulina, etc.). \n\
• Trabajo con un enfoque NO centrado en peso, lo que quiere decir que no será un valor que tomaremos en cuenta para tu tratamiento, sino que nos enfocaremos en otros factores que aporten a tu salud. \n\
• Crear un espacio seguro, libre de juicios, regaños y prohibiciones. \n\
• Mi objetivo como nutrióloga es ser una guía para que obtengas las herramientas necesarias para llevar a cabo tu alimentación, identificando qué, cuánto y cuándo comer sin depender de dietas estrictas y conteo de alimentos o calorías. \n\n",
  isForYou:
    "• Quieres tener una alimentación completa, suficiente y adecuada.\n\n\
• Has vivido en ciclos de pérdida y ganancia de peso.\n\n\
• Quieres practicar la alimentación intuitiva.\n\n\
• Estás cansada/o de seguir dietas restrictivas.\n\n\
• Quieres dejar la alimentación basada en culpa y miedo.\n\n\
• Quieres mejorar tu salud de manera integral.\n\n\
• Estás buscando sentirte mejor con tu cuerpo.\n\n\
• Quieres cultivar una relación sana con la comida y tu mente.\n\n\
• Quieres aprender a comer desde un lugar más libre y compasivo.",
  orientationText:
    " \
**Duración:**  15 minutos\n\n\
**Modalidad:**  En línea \n\n \
**Objetivos:**\n\n\
•	Conocernos.\n\
•	Identificar el motivo de consulta y cómo puedo ayudarte.\n\
•	Darte a conocer la dinámica de las consultas y el enfoque que trabajo.",
};
