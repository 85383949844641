import React from "react";
import { Title } from "./Title";
import ReactMarkdown from "react-markdown";

interface props {
  id: string;
  text: string;
  img: string;
  title: string;
}

export const CardAndPhoto = ({ id, text, img, title }: props) => {
  const style = {
    backgroundImage: "url(" + img + ")",
    backgroundSize: "auto",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div id={id} className="flex flex-wrap mb-10 relative">
      <div
        className="lg:w-1/2 w-full justify-center flex wow fade rounded-lg"
        style={style}
      >
        <img
          alt={title}
          src={img}
          className="object-contain  hover:rotate-1 transition ease-in-out duration-150 lg:opacity-0 opacity-100"
        />
      </div>
      <div className="lg:w:1/2 w-full p-10 bg-slate-50 lg:absolute card-absolute wow fadeInDown">
        <Title title={title} />
        <p className="montserrat parrafo mt-2">
          <ReactMarkdown children={text} />
        </p>
      </div>
    </div>
  );
};
