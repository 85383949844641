import { useEffect, useState } from "react";
import logo from "../../assets/logo-nutricion-sin-medida.png";
import { Navigation } from "./Navigation";

export const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const OpenMenu = () => {
    setOpenMenu((state) => !state);
  };
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        // Si es mayor a las dimensiones de una tablet (768px)
        setOpenMenu(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex row-auto  py-5 items-center px-10 sticky top-0 head-top">
      <div className="flex items-center space-x-4">
        <img
          src={logo}
          className="text-slate-900 dark:text-white w-auto h-14"
        />
        <div className="flex flex-col justify-items-center items-center">
          <p className="logo-text text-2xl text-browncustom mb-0">
            Brenda Montes de Oca <br />
          </p>
          <span className="text-sm montserrat text-greenutrialt">
            Nutricion sin medida
          </span>
        </div>
      </div>
      <nav className="h-fit ml-auto lg:flex hidden">
        <ul className="flex space-x-4">
          <Navigation isMobile={false} />
        </ul>
      </nav>
      <button onClick={OpenMenu} className="hover:scale-110 lg:hidden ml-auto">
        <span className="material-symbols-outlined">menu</span>
      </button>
      {openMenu && (
        <div className="fixed bg-beige h-screen w-screen md:w-1/2 right-0 top-0 p-10">
          <div className="w-full flex">
            <button onClick={OpenMenu} className="hover:scale-110 ml-auto">
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
          <ul className="flex flex-col space-y-4">
            <Navigation isMobile={true} setOpenMenu={setOpenMenu} />
          </ul>
        </div>
      )}
    </div>
  );
};
